export {default as Navbar} from './navbar/Navbar'
export {default as Hero} from './hero/Hero'
export {default as Frame} from './frame/Frame'
export {default as ProjectSection} from './project/ProjectSection'
export {default as ServicesWidget} from './widget/ServicesWidget'
export {default as Team} from './team/Team'
export {default as Testimonials} from './testimonials/Testimonials'
export {default as Contact} from './contact/Contact'
export {default as Footer} from './footer/Footer'

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 10
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);