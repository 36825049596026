import React from 'react'
import './hero.css'
import logo3d from '../../assets/3d.png'
import {motion} from 'framer-motion'
const Hero = () => {
 
return (
<div className='flex md:my-[50px] flex-col sm:flex-row sm:justify-center justify-between items-center monter  '>
  <motion.div
  animate={{x:[-200,0] ,  opacity : [0, 10]}}
  tranition ={{
  type : 'spring',
  stiffness:12,
  duration:3}}
  className='mx-4 md:mt-[50px] mt-[120px]' >
      <h5 className='text-bgColor dark:text-whiteColor md:text-[2.5rem] text-2xl lg:text-5xl '>The People Behind</h5>
      <h1 className='leading-none text-lightBlue text-[50px] md:text-[90px] mb-3'>MORO<span className='text-bgColor dark:text-whiteColor'>CODER</span></h1>
      <p className='max-w-[450px] text-bgColor tracking-wide dark:text-whiteColor/80 mb-8 text-[18px] dark:text-md'>We are a team of talented developers and designers who are passionate about creating beautiful, functional, and user-friendly websites and applications.</p>
      <a href="#contact" className=' bg-lightBlue text-whiteColor font-semibold tracking-wide rounded-md px-4 py-2 text-xl'>Contact Us</a>
  </motion.div>

  <div className=' md:w-[700px] hidden sm:block'>
    <img src={logo3d} alt="morocoder logo" />
  </div>
</div>
)
}

export default Hero
