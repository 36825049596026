import React, { useState , useEffect } from 'react'
import "./navbar.css"
import logo from '../../assets/logo.png'
import {motion ,  AnimatePresence } from 'framer-motion'
import {Mbtn , Xbtn , Day , Night} from './Svgs'


const Navbar = () => {

    const [toggle,setToggle] = useState(false)
    const [theme , setTheme] = useState('dark')
    useEffect(() => {
      if (theme === 'dark') {
          document.documentElement.classList.add('dark')
      } else {
          document.documentElement.classList.remove('dark')
        
      }

    }, [theme])
    const handleClickSwitch = () => {
      setTheme(theme ==='dark' ? ' light' : 'dark')
    }  


return (
<div>
  <nav className="relative mx-auto monter bg-lightBlue dark:bg-bgColorDak font-semibold dark:shadow-md shadow-black  py-1">

    <div className="flex items-center justify-between xl:justify-around md:mx-6 mx-2">
      <div className="p-1 lg:justify-center">
        <a href="/"> <img src={logo} alt="" className="md:w-[50px] w-[40px]" /></a>
      </div>
      

      <div className='flex'>
        
        <button className='md:hidden block px-4 text-whiteColor' onClick={()=>(setToggle(!toggle))}>
          {toggle ? <Xbtn/> : <Mbtn/>}
        </button>
      </div>

      <div className="hidden space-x-12 text-whiteColor text-[14px] md:flex  tracking-widest" >
        <a href="#services" className="hover:text-bgColor dark:hover:text-lightBlue  ">Services</a>
        <a href="#projects" className="hover:text-bgColor dark:hover:text-lightBlue">Projects</a>
        <a href="#testimonials" className="hover:text-bgColor dark:hover:text-lightBlue">Testimonials</a>
        <a href="#team" className="hover:text-bgColor dark:hover:text-lightBlue">Team</a>
        <a href='#contact' className="hover:text-bgColor dark:hover:text-lightBlue ">Contact</a>
        <button onClick={handleClickSwitch} className="text-white">
          {theme === 'dark' ? <Day/> : <Night/>}
        </button>
      </div>

     

    </div>
    <AnimatePresence>
      
        {toggle &&
          <motion.div 
          initial={{opacity : 0 } }
          animate = {{opacity:1}}
          exit={{opacity : 0}}
           className='md:hidden text-sm text-center text-whiteColor tracking-widest '>
            <div className='flex justify-center'> 
            <a href="#services" className="block font-semibold active:text-lightBlue rounded py-2 px-2 ">Services</a>
              <button onClick={handleClickSwitch} className="text-white md:hidden absolute right-7 mt-2">
                {theme === 'dark' ? <Day/> : <Night/>}
              </button>
            </div>
            <a href="#projects" className="  block font-semibold active:text-lightBlue rounded py-2 px-2 ">Projects</a>
            <a href="#testimonials" className="  block font-semibold active:text-lightBlue rounded py-2 px-2 ">Testimonials</a>
            <a href="#team" className="block font-semibold active:text-lightBlue rounded py-2 px-2">Team</a>
            <a href="#contact" className="  block font-semibold active:text-lightBlue rounded py-2 px-2 pb-3  ">Contact</a>
          
          </motion.div>
        }
      
    </AnimatePresence>
    
  </nav>

</div>
)
}

export default Navbar