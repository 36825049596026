import React from 'react'
import marouane from '../../assets/marouane.png'
import karim from '../../assets/karim.png'
import nabil from '../../assets/nabil.jpg'
import zack from '../../assets/adil.png'
const team = [
    {
        img: marouane , 
        fullname : 'Marouane Charafi',
        role : 'Front-End Developer',
        link: 'https://charafimarouane.com',
        git: 'https://github.com/charafimarouane',
        linkedin: 'https://linkedin.com/in/charafimarouane',
    },
    {
        img: karim , 
        fullname : 'Karim Haouat',
        role : 'Full-Stack Designer',
        link: 'https://haouatkarim.com',
        git: 'https://github.com/HaouatKarim',
        linkedin: 'https://linkedin.com/in/haouatkarim',
    },
    {
        img: zack , 
        fullname : 'Adil Zack',
        role : 'Full-Stack Developer',
        link: 'https://www.linkedin.com/in/adil-zak-434292217/',
        git: 'https://github.com/Baronsindo',
        linkedin: 'https://www.linkedin.com/in/adil-zak-434292217/',
    },
    {
        img: nabil , 
        fullname : 'Nabil Malki',
        role : 'Marketing Manager',
        link: 'https://www.linkedin.com/in/nabil-malki-9a757019b/',
        git: 'https://github.com/nabilmalki05',
        linkedin: 'https://www.linkedin.com/in/nabil-malki-9a757019b/',
    },
]

const Team = () => {
  return (
    <div className='lg:container lg:mx-auto flex flex-col justify-center items-center' id='team'>
    <h1 className="md:text-3xl text-2xl px-2 my-12 reveal monter text-center md:font-bold font-semibold text-lightBlue dark:text-whiteColor tracking-wide">Our Team</h1>
    <div className='grid gap-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-10 '>
        {team.map((member, index) => (
        <div key={index} className='flex flex-col justify-center items-center p-4 w-[360px] reveal cursor-pointer'>
            <img src={member.img} alt="" className='rounded-full w-[150px] h-[150px] border-2 border-lightBlue' />
            <h1 className='font-semibold text-[24px] text-center mt-3 dark:text-whiteColor text-black/80'>{member.fullname}</h1>
            <h2 className='font-semibold text-[18px] text-center text-lightBlue'>{member.role}</h2>
            <div className='flex justify-center space-x-6 my-4'>
                <a href={member.linkedin} target="_blank" rel="noreferrer">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-linkedin text-blakk dark:text-whiteColor/80 dark:hover:text-lightBlue hover:text-lightBlue " viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                </a>
                <a href={member.git} target="_blank" className='' rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                        className="bi bi-github text-blakk dark:text-whiteColor/80 dark:hover:text-lightBlue hover:text-lightBlue " viewBox="0 0 16 16">
                        <path
                            d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                        </svg>
                </a>
                <a href={member.link} target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg " width="22" height="22" fill="currentColor"
                            className="bi bi-box-arrow-up-right dark:text-whiteColor/80 dark:hover:text-lightBlue hover:text-lightBlue" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                            <path fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                        </svg>
                </a>
            </div>
        </div>
        ))}
    </div>
    </div>
  )
}

export default Team