import { Carousel } from 'flowbite-react'
import React from 'react'
import dma from '../../assets/dma.png'
import xtriko from '../../assets/xtriko.png'
import lp from '../../assets/lp.png'
import './style.css'


const ComunityModel = (props) => {
  return (
    <div className='flex flex-col justify-center mx-2 monter reveal'>
       
        <div className= 'px-8 py-6 bg-whiteColor dark:bg-lightBG rounded-md shadow-md shadow-black/50 md:w-[700px] mx-auto text-center '>
            <p className='text-xl font-semibold text-black/80 dark:text-whiteColor/80 my-4 '>
            {props.paragraph}
            </p>
            <div className='flex mx-6 mt-10'>
                <div className='mx-4 my-auto' ><img src={props.img} alt="" className='h-[60px] w-[60px]'/></div>
                <div>
                    <h2 className='font-bold text-xl text-black/80 dark:text-whiteColor'>{props.name}</h2>
                    <h3 className='text-lightBlue font-semibold'>{props.project}</h3>
                </div>
            </div>
        </div>
    </div>
  )
}


const Testimonials = () => {
  return (
    <div className='h-[350px] mt-24' id='testimonials'>
      
      <h1 className='md:text-3xl text-2xl px-2 my-12 reveal text-center md:font-bold font-semibold text-lightBlue dark:text-whiteColor monter tracking-wide'>Testimonials</h1>
        <Carousel slideInterval={5000}>
            <ComunityModel name='ABDERRAHMANE' img={dma} paragraph='" Great service, efficient communication and a really easy way to get a mortgage. "' project="Co-funder dmadelivery.ma" />
            <ComunityModel name='Paul Sandoval' img={xtriko} paragraph='" Thank you for sending it in three days before the deadline. Great work! "' project="Owner of Xtriko.com" />
            <ComunityModel name='Hicham Aboumerrouane' img={lp} paragraph='“ Excellent service at Morocoder! Thank you for the amazing work! ”' project="Co-funder Lalettrepeinte.ma" />
        </Carousel>

      
    </div>
  )
}



export default Testimonials