import React from 'react';

const services = [
  {
    title: 'Web Development',
    description: 'Designing and developing websites using various programming languages and frameworks.'
  },
  {
    title: 'Mobile App Development',
    description: 'Creating customized mobile applications for iOS and Android platforms.'
  },
  {
    title: 'Software Development',
    description: 'Developing software applications and programs for businesses and organizations.'
  },
  {
    title: 'Visual Identity',
    description: 'Working with businesses to create a unique and recognizable brand image  such as logos, color schemes, typography, and other graphic elements.'
  },
  {
    title: 'E-commerce Solutions',
    description: 'Providing solutions to enable businesses to sell their products or services online.'
  },
  {
    title: 'Digital Marketing',
    description: 'Assisting businesses in promoting their brand, products, or services using online marketing techniques.'
  },

 
];

const ServicesWidget = () => {
  const containerClasses = `md:p-4 p-2 rounded-lg text-colorPar monter lg:container lg:mx-auto my-[80px]`;
  const cardClasses = `shadow-md dark:hover:shadow-lightBlue p-4 rounded-lg dark:bg-lightBG bg-whiteColor text-colorPar reveal shadow-black/50`;

  return (
    <div className={containerClasses} id='services'>
      <h2 className="md:text-3xl text-2xl px-2 my-12 reveal text-center md:font-bold font-semibold text-lightBlue dark:text-whiteColor tracking-wide">Our Services</h2>
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10">
        {services.map((service, index) => (
          <div key={index} className={cardClasses}>
            <h3 className="text-xl font-semibold mb-2 text-bgColor cursor-pointer dark:text-lightBlue">{service.title}</h3>
            <p className="dark:text-whiteColor/80 text-bgColor/80 cursor-pointer">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesWidget;
