import React from 'react'
import './frame.css'
import SvgFrame from './SvgFrame'

const Frame = () => {
return (
<div className='reveal'>
    <div className='w-90 mx-auto shadow-md my-12'>
        <div className='bg-darkBlue h-[20px]'>

        </div>
        <div className=' h-[160px] flex justify-center bg-whiteColor dark:bg-lightBG '>
            <div className='container justify-center mb-3 flex  overflow-x-hidden '>
                <div className='flex items-center space-x-16 h-full md:animate-none animate-marque'>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.laravel}
                        {/* <div
                            className='absolute w-[76px]  rounded-xl bg-laravel -bottom-9 group/edit invisible group-hover/item:visible'>
                            <h2 className='text-center text-white font-bold font-sans '>Laravel</h2>
                        </div> */}
                    </div>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.Flutter}
               
                    </div>
                    <div className='flex-row group/item relative'>

                        {SvgFrame.React}

                    </div>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.Php}
                    </div>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.Node}
                    </div>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.Python}
                    </div>

                    <div className='flex-row group/item relative'>
                        {SvgFrame.Tailwind}
                    </div>
                    <div className='flex-row group/item relative'>
                        {SvgFrame.Angular}
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>
)
}

export default Frame