import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SvgContact from './SvgContact'
import './contact.css'



const Contact = () => {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName:'',
            email:'',
            subject:"",
            message:''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(15 , 'Must be less than 15 character').required('Required'),
            lastName: Yup.string().max(20, 'Must be less than 20 character').required("Required"),
            email: Yup.string().email('invalid email').required('Required'),
            subject: Yup.string().max(100, "Must be less than 30 character").required('Required'),
            message: Yup.string().max(350, 'Must be less than 350 character').required('Required')
        }),
        onSubmit: (values)=>{
            console.log(values);
        }
    })
    console.log(formik.touched);



    return (
    <div id='contact' className='md:flex justify-around py-20 monter '>
      <div className='md:w-full max-w-md mt-8 mx-auto mb-20 md:mx-4 w-[300px] reveal'> 
          <h1 className='text-3xl font-bold md:border-l-4 mx-auto pl-2 my-5 dark:text-whiteColor text-lightBlue md:text-left text-center border-lightBlue w-[200px] md:w-full'>Contact Us</h1>
          <p className='dark:text-colorPar text-black/70  md:text-left text-center my-10  '>We would love to hear from you! If you have any questions or comments, please feel free to reach out to us using the contact information below. We are always happy to chat and help in any way we can. </p>
          <p className='dark:text-colorPar text-black/70  md:text-left text-center my-10  '>Alternatively, you can fill out the form on this page and we will get back to you as soon as possible.
                Thank you for visiting our website and we look forward to connecting with you! </p>
         <div className='flex flex-row justify-center my-12 space-x-12'>
          <a href="https://www.linkedin.com/company/morocoder/">{SvgContact.Linkedin}</a>
          <a href="https://www.instagram.com/morocoders/" target='_blank' rel='noreferrer'>{SvgContact.Instagram}</a>
          <a href="/">{SvgContact.Github}</a>
          <a href="https://www.tiktok.com/@morocoder">{SvgContact.Tiktok}</a>
         </div>
      </div>
       
      <div className='md:w-full max-w-md mx-auto px-4 md:mx-4 reveal'>
      <form className=""
        onSubmit={formik.handleSubmit}
      >
          <div className='flex justify-between'>
              <div className='flex flex-col w-1/2'>
                  <label className='dark:text-gray-400 text-black my-1 flex text-right'>first name</label>
                  <input type="text" placeholder='' className='mr-3 dark:bg-lightBG bg-whiteColor/80 dark:text-white text-black dark:focus:text-white focus:text-black focus:outline-none focus:bg-white py-3 px-4 mb-3 rounded focus:border-2 focus:border-lightBlue' 
                        id='firstName' name='firstName' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? <p className='text-red-600'>{formik.errors.firstName}</p> : null}

              </div>
              <div className='flex flex-col w-1/2'>
                  <label className='dark:text-gray-400 text-black my-1 flex text-right '>last name</label>
                  <input type="text" placeholder='' className='dark:bg-lightBG bg-whiteColor/80 dark:text-white text-black dark:focus:text-white focus:text-black focus:outline-none focus:bg-white py-3 px-4 mb-3 rounded focus:border-2 focus:border-lightBlue' 
                        id='lastName' name='lastName' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? <p className='text-red-600'>{formik.errors.lastName}</p> : null}
              </div>
          </div>
          <div className='flex flex-col'>
              <label className='dark:text-gray-400 text-black my-1 flex text-right '>Email</label>
              <input type="email" placeholder='' className='dark:bg-lightBG bg-whiteColor/80 dark:text-white text-black dark:focus:text-white focus:text-black focus:border-2 focus:border-lightBlue focus:outline-none focus:bg-white py-3 px-4 mb-3 rounded' 
                    id='email' name='email' onChange={formik.handleChange} value={formik.values.email}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? <p className='text-red-600'>{formik.errors.email}</p> : null}
          </div>
          <div className='flex flex-col'>
            <label htmlFor="subject" className='dark:text-gray-400 text-black my-1 flex text-right'>
                Subject
            </label>
            <input type="text"
            className='dark:bg-lightBG bg-whiteColor/80 dark:text-white text-black dark:focus:text-white focus:text-black focus:border-2 focus:border-lightBlue focus:outline-none focus:bg-white py-3 px-4 mb-3 rounded'
            id='subject' name='subject' onChange={formik.handleChange} value={formik.values.subject}
            onBlur={formik.handleBlur}>
            </input>
            {formik.touched.subject && formik.errors.subject ? <p className='text-red-600'>{formik.errors.subject}</p>: null }
          </div>
          <div className="">
              <label className="dark:text-gray-400 text-black my-1 flex text-right" htmlFor="grid-password">
                  Message
              </label>
              <textarea
                  className=" no-resize w-full dark:bg-lightBG bg-whiteColor/80 dark:text-white text-black dark:focus:text-white focus:text-black rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-2 focus:border-lightBlue focus:bg-white h-48 "
                  id="message" name='message' onChange={formik.handleChange} value={formik.values.message}
                  onBlur={formik.handleBlur}
              ></textarea>
                {formik.touched.message && formik.errors.message ? <p className='text-red-600'>{formik.errors.message}</p> : null}
          </div>
         <div className='flex justify-start pb-5 my-3'>
         <button type='submit' className='w-full h-[40px] capitalize rounded-lg font-bold text-white bg-lightBlue'>submit</button>
         </div>
  
      </form>
      </div>
    
    </div>
  )
}

export default Contact