import { motion } from 'framer-motion'
import React from 'react'
import xtriko from '../../assets/xtriko.png'
import dma from '../../assets/dma.png'
import queme from '../../assets/queme.png'
import h2o from '../../assets/h2o.png'
import './style.css'

const args = {
  title : 'XTRIKO' ,
  paragraph : 'Xtriko is a Professional BEST & CHEAP IPTV PROVIDER Platform. Here we will provide you only interesting content, which you will like very much.',
  img : xtriko ,
  classA : 'text-xtrikoColor',
  classD : 'absolute w-[350px] h-[350px] -top-[4.2em] -right-[100px] z-20 bg-xtrikoColor rounded-full ',
  classC : 'absolute w-[250px] h-[250px] -top-[3.2em] -left-[100px] z-10 bg-xtrikoBG rounded-full ',
  link : "https://xtriko.com"
}
const args1 = {
  title : 'DMA DELIVERY' ,
  paragraph : '"DMA DELIVERY" is a delivery service offered by DMA INVEST, a company specializing in logistics solutions in Morocco.',
  img : dma ,
  classA : 'text-dmaColor',
  classD : 'absolute w-[350px] h-[350px] -top-[4.2em] -right-[100px] z-20 bg-dmaColor rounded-full ',
  classC : 'absolute w-[250px] h-[250px] -top-[3.2em] -left-[100px] z-10 bg-dmaBG rounded-full ',
  link : "https://dmadelivery.ma"
}
const args2 = {
  title : 'QUEME' ,
  paragraph : 'From questions about a favorite film to inquiries pertaining to trust and intimacy, here is that app that could help you achieve that very fast.',
  img : queme ,
  classA : 'text-quemeColor',
  classD : 'absolute w-[350px] h-[350px] -top-[4.2em] -right-[100px] z-20 bg-quemeColor rounded-full ',
  classC : 'absolute w-[250px] h-[250px] -top-[3.2em] -left-[100px] z-10 bg-bgLight rounded-full ',
  link : "https://xtriko.com"
}
const args3 = {
  title : 'H2O natation' ,
  paragraph : 'H2O natation est une entreprise qui a pour mission de former les futures générations de nageurs',
  img : h2o ,
  classA : 'text-H2oColor',
  classD : 'absolute w-[350px] h-[350px] -top-[4.2em] -right-[100px] z-20 bg-H2oColor rounded-full ',
  classC : 'absolute w-[250px] h-[250px] -top-[3.2em] -left-[100px] z-10 bg-h2oBG rounded-full ',
  link : "https://h2onatation.com"
}

function Arrow() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
    </svg>
  )
}
function Card(props){

  return(
    <motion.div className='w-[325px] h-[500px]  rounded-xl overflow-hidden shadow-md shadow-black/50 bg-whiteColor dark:bg-lightBG relative cursor-pointer md:mx-6 md:my-6 m-3'
      whileHover={{scale:1.1 }}
    
    >
        <div className='flex flex-col justify-end h-2/3'>
          <div className={props.classD}>
          </div>
          <div className={props.classC}>
          </div>
          <div className='flex justify-center my-8'>
            <img src={props.img} alt="" style={{width : '150px' , oheight : '150px' , zIndex : 20 }}  />
          </div>
          <div className='flex justify-center items-center space-x-3'>
            <h1 className='dark:text-white text-black font-semibold text-3xl text-center z-10 '>{props.title}</h1>
            <a href={props.link} target="_blank" rel="noopener noreferrer" className={props.classA}> 
              <Arrow/>
            </a>
          </div>
        </div>
        <div className='flex flex-col justify-center h-1/3 items-center pb-5'>
          <p className='px-3 text-center dark:text-white/60  text-black/60 inter '>{props.paragraph}</p>
        </div>
    </motion.div>

  )
}
const ProjectSection = () => {
  

return (
  
  <div className="flex flex-col pb-12 justify-center items-center" id='projects'>
    <h1 className='md:text-3xl text-2xl px-2 my-12 reveal  text-center md:font-bold font-semibold text-lightBlue dark:text-whiteColor monter tracking-wide'>Our Projects</h1>
    <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-10 reveal '>
      <Card {...args}/>
      <Card {...args2}/>
      <Card {...args1}/>
      <Card {...args3}/>
    </div>
  </div>
)
}

export default ProjectSection

