import './App.css';
import {Frame, Hero, Navbar, ServicesWidget, ProjectSection, Team, Footer, Contact, Testimonials} from './components';

function App() {
  return (
    <div className="">
      <div className= 'bg-bgLight dark:bg-bgColorDak '>
        <Navbar/>
        <Hero/>
        <ServicesWidget/>
        <Frame/>
        <ProjectSection/>
        <Team/>
        <Testimonials/>
        <Contact/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
